import React, { useState } from "react"
// Components
import { graphql } from 'gatsby';
import { Formik } from 'formik';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container1200 } from '../components/Container1200';
import { PageTitle } from '../components/PageTitle';
// Images
import metaImage from '../images/icons/favicon-512.png';
// Style
import {
  Blurb,
  ButtonSubmit,
  Column,
  FormGroup,
  FormMessage,
  Input,
  Row,
  Textarea
} from '../styles/pages-styles/contact-us.styles';

export const query = graphql`
  query ContactQuery {
    allSanityPageContact {
      edges {
        node {
          body
          successMessage
          title
        }
      }
    }
  }
`;

const ContactUsPage = ({ data }) => {
  const [formSubmitted,setFormSubmitted] = useState(false);
  const [submitSuccess,setSubmitSuccess] = useState(false);
  const [submitError,setSubmitError] = useState(false);
  const pageData = data.allSanityPageContact.edges[0].node;

  const encode = (data) => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  }

  return (
    <Layout>
      <SEO
        description={pageData.body}
        image={`https://www.ambitiousbeaver.com${metaImage}`}
        path="/contact-us"
        title="Contact Us"
      />
      <Container1200>
        <PageTitle>{pageData.title}</PageTitle>
        <Row>
          <Column>
            <Blurb>{pageData.body}</Blurb>
          </Column>
          <Column>
            <Formik
              initialValues={{
                email: '',
                message: '',
                name: ''
              }}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = true;
                }
                return errors;
              }}
              onSubmit={(values, { resetForm,setSubmitting }) => {
                setSubmitError(false);
                setSubmitSuccess(false);
                setFormSubmitted(false);
                let body = encode({
                  'form-name': 'contact',
                  ...values,
                });
                fetch("/", {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                  body: body,
                })
                  .then(() => {
                    setSubmitting(false);
                    setSubmitSuccess(true);
                    setFormSubmitted(true);
                    resetForm();
                  })
                  .catch(error => {
                    setSubmitting(false);
                    setSubmitError(true);
                    setFormSubmitted(true);
                  })
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <form
                  name="contact"
                  method="post"
                  onSubmit={handleSubmit}
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="contact" value="contact" />
                  <FormGroup>
                    <Input
                      id="name"
                      type="text"
                      name="name"
                      placeholder="Your Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      id="email"
                      type="email"
                      name="email"
                      placeholder="Your Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      err={errors.email && touched.email}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Textarea
                      id="message"
                      type="text"
                      name="message"
                      placeholder="Your Message..."
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.message}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ButtonSubmit type="submit" disabled={values.email === '' || isSubmitting}>
                      Submit
                    </ButtonSubmit>
                  </FormGroup>
                  <FormGroup>
                    <FormMessage submitted={formSubmitted}>
                      {submitSuccess && pageData.successMessage}
                      {submitError && <>There was an error sending your message! Please try again later.</>}
                    </FormMessage>
                  </FormGroup>
                </form>
              )}
            </Formik>
          </Column>
        </Row>
      </Container1200>
    </Layout>
  )
}

export default ContactUsPage
