import styled, { css } from 'styled-components';
import { colors as c } from '../../styles/colors';

export const Blurb = styled.p`
  font-size: 2.4rem; 
  line-height: 119%;
  padding: 30px;
  background-color: ${c.greenDark};
  border-radius: 8px;
  margin-bottom: 2.5rem;

  @media (min-width: 768px) {
    margin: 0;
    font-size: 3rem;
  }
`;

export const Row = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
  }
`;

export const Column = styled.div`
  flex: 1;

  &:last-of-type {

    @media (min-width: 768px) {
      margin-left: 30px;
    }

    @media (min-width: 1200px) {
      margin-left: 60px;
    }
  }
`;

export const FormGroup = styled.div`
  width: 100%;
  margin-bottom: 2.5rem;

  ${({ centered }) => centered && `
    display: flex;
    justify-content: center;
  `}
`;

export const Input = styled.input`
  display: block;
  width: 100%;
  border: 2px solid transparent;
  background-color: ${c.grayPaper};
  font-family: Thasadith;
  font-size: 2.2rem;
  padding: 1rem;
  border-radius: 8px;

  ${({ err }) => err && `
    border: 2px solid ${c.redError};
  `}

  &:active {
    outline: 0;
  }

  &:focus {
    outline: 0;
  }
`;

export const Textarea = styled.textarea`
  display: block;
  width: 100%;
  border: none;
  background-color: ${c.grayPaper};
  font-family: Thasadith;
  font-size: 2.2rem;
  padding: 1rem;
  border-radius: 8px;

  &:active {
    outline: 0;
    border: 0;
  }

  &:focus {
    outline: 0;
    border: 0;
  }
`;

export const ButtonSubmit = styled.button`
  display: inline-block;
  background-color: ${c.greenCTA};
  text-align: center;
  font-family: Thasadith;
  font-size: 2.2rem;
  font-weight: bold;
  color: #fff;
  padding: 1rem 3rem;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

export const FormError = styled.div`

`;

export const FormMessage = styled(Blurb)`
  opacity: 0;
  transition: all .25s linear;

  ${({ submitted }) => submitted && css`
    opacity: 1;
  `}
`;
