import styled from 'styled-components';

export const Container = styled.h1`
  font-family: Thasadith;
  font-size: 5rem;
  padding: 0 0 30px 20px;

  @media(min-width: 768px) {
    font-size: 6rem;
    padding: 0 0 60px 40px;
  }

  @media(min-width: 992px) {
    font-size: 8.1rem;
  }
`;
